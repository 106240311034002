/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'antd';

import Subscribe from '../../components/Subscribe';

import FooterWrapper from './styles';

class Footer extends Component {
  render() {
    const { showSubscribe } = this.props;

    return (
      <FooterWrapper showSubscribe={showSubscribe}>
        <div className="subscribe-box-parent">
          <div className="subscribe-box-children">
            {showSubscribe &&
              <Row type="flex" align="middle" className="subscribe-container">
                <Col className="subscribe-wrapper">
                  <Subscribe />
                </Col>
              </Row>
            }
          </div>
        </div>

        <footer style={{ backgroundColor: '#ffffff' }}>
          <Row align="top" className="footer-bottom" gutter={16}>
            <Col xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 24, order: 3 }} lg={{ push: 1, span: 8, order: 0 }} className="col-1st">
              <a href="/"><img className="logo" src={`${process.env.PUBLIC_URL}/titlelogics/logo.png`} alt="" /></a>
              <p style={{ padding: '5px 0' }}>© Copyright 2020 Title Logics Corporation<br /> All Rights Reserved.</p>
            </Col>

            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 8, order: 0 }} lg={{ push: 1, span: 5, order: 1 }} className="col-2nd">
              <h6>Company</h6>
              <nav>
                <a href="/">Home</a>
                <a href="features">Features</a>
                <a href="pricing">Pricing</a>
                <a href="contact">Contact</a>
              </nav>
            </Col>

            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg={{ push: 1, span: 5, order: 2 }} className="col-3rd">
              <h6>Legal</h6>
              <nav>
                <a href="legal-notice">Legal Notice</a>
                <a href="privacy-policy">Privacy Policy</a>
                <a href="terms-of-user">Terms of Service</a>
              </nav>
            </Col>

            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 2 }} lg={{ push: 1, span: 5, order: 3 }} className="col-4th">
              <h6>Follow us</h6>
              <nav style={{ padding: '5px 0' }}>
                <a target="_blank" href="https://twitter.com/titlelogics">
                  <img src={`${process.env.PUBLIC_URL}/social-twitter.png`} className="social-icon" alt="" />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/titlelogics">
                  <img src={`${process.env.PUBLIC_URL}/social-linkedin.png`} className="social-icon" alt="" />
                </a>
                <a target="_blank" href="https://www.facebook.com/titlelogics">
                  <img src={`${process.env.PUBLIC_URL}/social-facebook.png`} className="social-icon" alt="" />
                </a>
                <a target="_blank" href="https://www.instagram.com/titlelogics">
                  <img src={`${process.env.PUBLIC_URL}/social-instagram.png`} className="social-icon" alt="" />
                </a>
              </nav>
            </Col>
          </Row>
        </footer>
      </FooterWrapper>
    )
  }
}

export default connect(
  state => ({
    showSubscribe: state.common.showSubscribe
  }),
  dispatch => bindActionCreators({

  }, dispatch)
)(Footer);
