import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'antd';
import { get } from 'lodash';

import { mailContact } from '../../actions/email';
import ModalEmailContact from '../ModalEmailContact';

import SubscribeForm from './subscibeForm';
import SubscribeWrapper from './styles';

class Subscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };
  }

  onShowModal = () => {
    this.setState({ isVisible: true });
  };

  onCloseModal = () => {
    this.setState({ isVisible: false });
  };

  onSubmit = (values, resetForm) => {
    return window.open(`https://tsms.titlelogics.com/sign-up?email=${values.email || ''}`, '_blank');
    // const { mailContact } = this.props;
    //
    // const reqBody = {
    //   email: values.email,
    //   type: 'SUBSCRIBER'
    // };
    //
    // this.onShowModal();
    //
    // mailContact(reqBody);
    // if (resetForm) {
    //   resetForm();
    // }
  };

  render() {
    const { isVisible } = this.state;
    const { bottom, mailContactRes } = this.props;

    const isRequesting = get(mailContactRes, 'requesting');

    return (
      <SubscribeWrapper bottom={bottom}>
        <div className="left-comming">
          <Row type="flex" align="top">
            <Col xs={24} sm={24} md={24} lg={24}>
              <h1 className="comming-title">Get 5 free title searches now.</h1>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <p className="comming-intro">No credit card required.</p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <SubscribeForm
                isRequesting={isRequesting}
                onSubmit={this.onSubmit}
              />
            </Col>
          </Row>
        </div>

        <img src={`${process.env.PUBLIC_URL}/images/ellipse.png`} className="ellipse-bottom" alt="bottom" />
        <img className="left-phone-overlay" src={`${process.env.PUBLIC_URL}/titlelogics/connected.png`} alt="left" />

        <ModalEmailContact
          isVisible={isVisible}
          onCloseModal={this.onCloseModal}
          title="Thanks for subscribing!"
          messages="You will be among the first to hear updates about TitleLogics news and products!"
        />
      </SubscribeWrapper>
    );
  }
}

export default connect(
  state => ({
    mailContactRes: state.email.mailContact
  }),
  dispatch => bindActionCreators({
    mailContact
  }, dispatch)
)(Subscribe);
