import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import category from './category';
import common from './common';
import email from './email';
import member from './member';

const commonConfig = {
  key: 'common',
  storage: localForage,
  whitelist: ['countries', 'releasedDate']
};

const reducer = {
  category,
  email,
  common: persistReducer(commonConfig, common),
  member
};
export default reducer;
