import http from '../services/http';

export default class Email {
  static contact(body) {
    // FOR SUBSCRIBER
    // {
    //   email: required,
    //   type: 'SUBSCRIBER'
    // }
    //
    // FOR CONTACT_US
    // {
    //   name: required,
    //   email: required,
    //   phone: required,
    //   message: required and string length >= 10,
    //   type: 'CONTACT_US'
    // }
    return http.post('/EmailContacts', body);
  }
}
