import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const FooterWrapper = styled.div`
  background-color: #f8fafb;
  padding-top: 50px;

  .subscribe-box-parent {
    width: 100%;

    .subscribe-box-children {
      max-width: 1200px;
      margin: auto;
      position: relative;
    }
  }

  .footer-bottom {
    padding: ${props => props.showSubscribe ? '50px' : '50px 80px'};
    margin-top: 50px;

    p {
      color: #282828;
    }

    h6 {
      margin-bottom: 15px;
    }

    .col-1st {
      .logo {
        width: auto;
        height: 35px;
        object-fit: contain;
        margin-left: -10px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        margin-top: 15px;
      }
    }

    .col-2nd {
      h6 {
        font-size: 20px;
        font-weight: 700;
      }

      nav {
        a {
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: black;
          margin: 8px 0;
        }

        a:hover {
          color: #2062b4;
        }
      }
    }

    .col-3rd {
      h6 {
        font-size: 20px;
        font-weight: 700;
      }

      nav {
        a {
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: black;
          margin: 8px 0;
        }

        a:hover {
          color: #2062b4;
          font-weight: 500;
        }
      }
    }

    .col-4th {
      h6 {
        font-size: 20px;
        font-weight: 700;
      }

      nav {
        .social-icon {
          widht: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }
  }

  .subscribe-box-parent {
    // position: absolute;
    // bottom: 72%;
    // left: 0;

    .subscribe-container {
      // width: 100%;
      // padding: 0 100px;
  
      .subscribe-wrapper {
        height: 280px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .divide {
      padding: 50px 10px;
    }

    .footer-bottom {
      padding-top: ${props => props.showSubscribe ? '50px' : '50px'};
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;

      .col-1st {
        p {
          font-size: 15px;
          margin-top: 0;
        }
      }

      .col-2nd {
        h6 {
          font-size: 18px;
        }
  
        nav {
          a {
            font-size: 14px;
          }
        }
      }

      .col-3rd {
        h6 {
          font-size: 18px;
        }
  
        nav {
          a {
            font-size: 14px;
          }
        }
      }

      .col-4th {
        h6 {
          font-size: 18px;
        }
  
        nav {
          .social-icon {
            widht: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

      .col-1st, .col-2nd, .col-3rd, .col-4th {
        margin-bottom: 30px;
      }
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 576px) {
    .subscribe-box-parent {
      bottom: 86%;

      .subscribe-container {
        padding: 0 25px;
  
        .subscribe-wrapper {
          height: 240px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 10px;

    .subscribe-box-parent {
      .subscribe-container {
        padding: 0 10px;
  
        .subscribe-wrapper {
          // height: 220px;
        }
      }
    }

    .logo {
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .divide {
      padding: 50px 60px;
    }

    .footer-bottom {
      .col-1st {
        p {
          font-size: 18px;
          margin-top: 0;
        }
      }

      .col-2nd {
        h6 {
          font-size: 22px;
        }
  
        nav {
          a {
            font-size: 16px;
          }
        }
      }

      .col-3rd {
        h6 {
          font-size: 22px;
        }
  
        nav {
          a {
            font-size: 16px;
          }
        }
      }

      .col-4th {
        margin-bottom: 50px;
        h6 {
          font-size: 22px;
        }
  
        nav {
          .social-icon {
            widht: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }
      }

      .col-2nd, .col-3rd, .col-4th {
        margin-bottom: 30px;
      }
    }

    .subscribe-box-parent {
      bottom: 78%;

      .subscribe-container {
        padding: 0 50px;

        .subscribe-wrapper {
          height: 260px;
        }
      }
    }

    .logo {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .subscribe-container {
      padding: 0 50px;
    }
  }

  @media (min-width: 1200px) {
    .subscribe-container {
      padding: 0;
    }
  }
`

export default WithDirection(FooterWrapper);
