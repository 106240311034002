import React from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './containers/App';

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
    }
  />
);

const MainRoutes = ({ history, isAuthenticated }) => {
  return (
    <ConnectedRouter history={history}>
      <Router>
        <Switch>
          <PublicRoute
            path="/"
            component={App}
            isAuthenticated={true}
          />
        </Switch>
      </Router>
    </ConnectedRouter>
  );
};

export default MainRoutes
