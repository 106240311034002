/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import { Row, Col, Form, Button } from 'antd';

import FieldFloatingValidation  from '../../components/Validation/FieldFloatingValidation';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 }
  }
};

const validationSchema = Yup.object().shape({
  // email: Yup.string().email('Invalid email address').required('Email is required.')
});

class SubscribeForm extends Component {
  static propTypes = {
    onChangeCatalog: PropTypes.func,
    rewardsCatalogData: PropTypes.array
  };

  render() {
    const { handleSubmit, isRequesting } = this.props;

    return (
      <Form {...formItemLayout} layout="horizontal" style={{ width: '100%' }}>
        <Row type="flex" align="top">
          <Col xs={15} sm={15} md={18} lg={18} className="email-field">
            <Field
              name="email"
              type="text"
              label=""
              component={FieldFloatingValidation}
              containerClassName="email-style"
              required
            />
          </Col>

          <Col xs={9} sm={9} md={6} lg={6}>
            <Button
              onClick={handleSubmit}
              className="subcribe-btn"
              disabled={isRequesting}
              loading={isRequesting}
            >
              FREE TRIAL
            </Button>
          </Col>
          <Col xs={24} sm={24} md={20} lg={20}>
            <p className="privacy-intro">We care about protecting your data. Here’s our <a href="/privacy-policy">Privacy Policy.</a></p>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default compose(
  connect(
    state => ({
    }),
    dispatch => bindActionCreators({
    }, dispatch)
  ),
  withFormik({
    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {};
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      props.onSubmit(values, resetForm);
      setSubmitting(false);
    }
  })
)(SubscribeForm);
