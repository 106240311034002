import { createActions, createAction } from 'redux-actions';
import * as serviceWorker from '../serviceWorker';
import { getView } from '../utils/common';
import Api from '../api';

export const checkServiceWorker = createAction('CHECK_SERVICE_WORKER');
export const toggleCollapsed = createAction('TOGGLE_COLLAPSED');
export const handleToggleAll = createAction('HANDLE_TOGGLE_ALL');
export const toggleOpenDrawer = createAction('TOGGLE_OPEN_DRAWER');
export const setShowSubscribeContainer = createAction('SET_SHOW_SUBSCRIBE_CONTAINER');
export const getHeightScrollY = createAction('GET_HEIGHT_SCROLL_Y');

export const toggleAll = (width, height) => (dispatch) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  dispatch(handleToggleAll({ collapsed, view, height }));
};

export const initServiceWorker = () => (dispatch, getState) => {
  const releasedDate = getState().common.releasedDate || '';
  const currentTimeEnv = process.env.REACT_APP_TIMESTAMP.toString();
  if (releasedDate.toString() !== currentTimeEnv) {
    dispatch(checkServiceWorker(currentTimeEnv));
    serviceWorker.unregister();
  } else {
    serviceWorker.register();
  }
};

/** FETCH INFO STOCK **/
const { fetchInfoStockRequest, fetchInfoStockSuccess, fetchInfoStockFail } = createActions({
  FETCH_INFO_STOCK_REQUEST: () => { },
  FETCH_INFO_STOCK_SUCCESS: data => ({ data }),
  FETCH_INFO_STOCK_FAIL: error => ({ error })
});

export const fetchInfoStock = () => (dispatch) => {
  dispatch(fetchInfoStockRequest());

  return Api.Common.fetchInfoStock()
    .then(({ data }) => {
      dispatch(fetchInfoStockSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchInfoStockFail(error));
    });
};
