import 'antd/dist/antd.css';
import { getAnalytics } from 'firebase/analytics';

import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import './index.css';

if ('serviceWorker' in navigator && navigator.serviceWorker && navigator.serviceWorker.ready) {
  navigator.serviceWorker.ready
    .then(registration => {
      registration.unregister();
    })
    .catch(error => {
      console.error(error.message);
    });
}

ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyChrrWsrKnnkoHiVFGHBXS-FtjxASyeDeA',
  authDomain: 'titlegistics.firebaseapp.com',
  projectId: 'titlegistics',
  storageBucket: 'titlegistics.appspot.com',
  messagingSenderId: '801422603883',
  appId: '1:801422603883:web:ec0de5c072b01ced99a32e',
  measurementId: 'G-VBF5LGH837',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
