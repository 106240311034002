/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col, Button } from 'antd';
import { get } from 'lodash';

import ModalEmailContact from '../../components/ModalEmailContact';

import NavWrapper from './styles';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isToggle: false,
      isInvestorBrowser: false,
      isInvestorMobile: false
    };

    this.navOptions = [
      { path: '/', label: 'HOME' },
      { path: '/features', label: 'FEATURES' },
      { path: '/pricing', label: 'PRICING' },
      { path: '/contact', label: 'CONTACT' }
    ];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    return this.setState({ isInvestorMobile: false });
  };

  handleOnToggle = () => {
    const { isToggle } = this.state;
    this.setState({ isToggle: !isToggle });
  };

  handleOnClickInvestorOnBrowser = () => {
    this.setState({ isInvestorBrowser: !this.state.isInvestorBrowser });
  };

  handleOnClickInvestorOnMobile = () => {
    this.setState({ isInvestorMobile: !this.state.isInvestorMobile });
  };

  onShowModal = () => {
    window.open('https://tsms.titlelogics.com/', '_blank');
  };

  onCloseModal = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { isToggle, isInvestorBrowser, isVisible } = this.state;
    const { history } = this.props;

    const pathname = isInvestorBrowser ? '/investors' : get(history, 'location.pathname', '');

    return (
      <NavWrapper isToggle={isToggle} isInvestorBrowser={isInvestorBrowser}>
        <Row type="flex" align="middle">
          <Col xs={13} sm={13} md={16} lg={5} className="logo-container">
            <a href="/">
              <img className="logo" src={`${process.env.PUBLIC_URL}/titlelogics/logo.png`} alt="" />
            </a>
          </Col>

          <Col xs={0} sm={0} md={0} lg={14} className="menu-container">
            <nav className="parent-nav">
              {
                this.navOptions.map((ele, id) => {
                  const activedColorNav = {
                    color: pathname === ele.path ? '#2062b4' : '#44444f',
                    borderBottom: pathname === ele.path && '2px solid'
                  };

                  return (
                    <div key={id} style={{ display: 'inline-block' }}>
                      <a href={ele.path} style={activedColorNav}>{ele.label}</a>
                    </div>
                  );
                })
              }
            </nav>
          </Col>

          <Col xs={7} sm={7} md={6} lg={5} className="get-btn-container">
            <Button type="primary" className="notified-btn" onClick={this.onShowModal}>
              CLIENT LOG IN
            </Button>
          </Col>

          <Col xs={3} sm={3} md={2} lg={0} className="icon-container">
            <a href={null} className="icon" onClick={this.handleOnToggle}>
              {
                isToggle ? <i className="fa fa-times icon-collapse" /> : <i className="fa fa-bars icon-collapse-out" />
              }
            </a>
          </Col>
        </Row>

        <Row align="top" className="dropdown-container">
          {
            this.navOptions.map((ele, id) => {
              return (
                <Col key={id} xs={24} sm={24} md={24} lg={0} className="item-dropdown">
                  <a id="investor-label" key={id} href={ele.path}>{ele.label}</a>
                </Col>
              );
            })
          }
        </Row>

        <ModalEmailContact
          isHasForm
          isVisible={isVisible}
          onCloseModal={this.onCloseModal}
          title="Get free $250 usage credits now!"
          messages={`Get updates on new press releases, products, and TitleLogics newsletters.`}
        />
      </NavWrapper>
    );
  }
}

export default connect(
  state => ({
    collapsed: state.common.collapsed
  }),
  dispatch => bindActionCreators({}, dispatch)
)(Nav);
