import {
  find,
  floor,
  get,
  parseInt,
  random,
  map
} from 'lodash';
import moment from 'moment';

export const getView = (width) => {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
};

export const getFileFromType = (data, type) => {
  return find(data, { type });
};

export const convertDurationToMinuteString = value => {
  const duration = moment.duration(parseInt(value), 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  if (hours > 0) {
    return `${hours}h ${minutes}min ${seconds}sec`;
  }
  if (minutes > 0) {
    return `${minutes}min ${seconds}sec`;
  }
  return `${seconds}sec`;
};

export const convertDurationToMinute = (value, notShowMinute) => {
  const minute = floor(value / 60, 0);
  const seconds = floor(value % 60, 0);
  if (notShowMinute) {
    return `${minute < 10 ? `${minute}` : minute}`;
  }
  // return `${minute < 10 ? `0${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${minute < 10 ? `${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const createDefaultUploadFile = (data, isSoundFile) => {
  let name = get(data, 'name', '');
  let url = get(data, 'url', '');
  const id = get(data, 'id', '');
  if (!isSoundFile) {
    const imageData = getFileFromType(data, 'medium', '');
    name = get(imageData, 'name', '');
    url = get(imageData, 'url', '');
  }
  // uid must be string number
  return [{
    uid: `${moment()
      .valueOf()}`, id, name, url, status: 'done'
  }];
};

export const createDefaultFileImage = data => {
  const id = get(data, 'id');
  const name = get(data, 'data.0.name');
  const url = get(data, 'data.0.url');

  if (!id) {
    return null;
  }
  return [{ uid: id, name, url, status: 'done', id }];
};

export const createDefaultFileMp4 = (data) => {
  const id = get(data, 'id');
  const name = get(data, 'data.0.name');
  const url = get(data, 'data.0.url');

  if (!id) {
    return null;
  }

  // uid must be string number
  return [{ uid: id, name, url, status: 'done', id }];
};

export const getDurationAudio = (file) => {
  return new Promise((resolve) => {
    const objectURL = URL.createObjectURL(file);
    const myAudio = new Audio([objectURL]);
    myAudio.addEventListener('canplaythrough', () => {
      URL.revokeObjectURL(objectURL);
      resolve({
        file,
        duration: myAudio.duration
      });
    }, false);
  });
};

export const upperCaseFirstLetter = (text) => {
  return `${text.charAt(0)
    .toUpperCase()}${text.slice(1)}`;
};

export const getQueryParam = (name, defaultData = '') => {
  const q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
  return q ? q[1] : defaultData;
};

export const isImage = url => (/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/.test(url));

export const widthModal = (width) => {
  if (width < 768) return width;
  if (width > 767 && width < 992) return width / 2 + 100;
  if (width > 991 && width < 1025) return width / 2;
  if (width > 1024 && width < 1220) return width / 2;
  return width / 3;
};

export const getAvatarBg = () => {
  const number = random(1, 8);
  return {
    blue: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-blue.png`,
    gray: `${process.env.PUBLIC_URL}/images/avatar-bg/${number}-gray.png`
  }
};

export const getHeightOffsetTopAnimations = () => {
  const height = document.getElementsByClassName('active-animation');
  const listHeightAnimation = map(height, ele => {
    const heightTemp = get(ele, 'offsetTop', 0);
    return heightTemp;
  });
  return listHeightAnimation;
};
