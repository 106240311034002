import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const BodyWraper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  .close-btn {
    align-self: flex-end;
    border: 0;
    padding: 0;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .logo {
    width: 120px;
    height: 70px;
    object-fit: contain;
    margin-bottom: 15px;
  }

  .title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }

  .messages {
    color: #535564;
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 2;
    text-align: center;
    margin: 0 50px;
  }

  .ant-input-group-addon {
    padding: 0;
    border: 0;
  }

  .ant-input-group .ant-input {
    color: black;
    font-weight: 500;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-container {
    padding: 0 50px;

    .email-field {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  
    .subcribe-btn {
      height: 40px;
      width: 100%;
      border: none;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: linear-gradient(to right, #3c84f7, #739ae5);
  
      span {
        color: white;
      }
    }
  }

  .back-btn {
    border: 1px solid white;
    border-radius: 5px;
    background-color: #3c84f7;
    margin: 10px 0;
    height: 40px;

    span {
      font-size: 13px;
      color: white;
    }
  }

  @media only screen and (max-width: 767px) {
    .title {
      font-size: 20px;
    }

    .messages {
      margin: 0;
    }

    .form-container {
      padding: 0 10px;
      text-align: left;

      .email-field {
        margin-right: 20px;
      }
    
      .subcribe-btn-container {
        .subcribe-btn {
          width: 50%;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .title {
      font-size: 20px;
    }

    .form-container {
      padding: 0 40px;

      .email-field {
        margin-right: 10px;
      }

      .subcribe-btn-container {
        .subcribe-btn {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 991px) and (max-width: 1024px) {
    padding: 10px 30px;

    .title {
      font-size: 20px;
    }

    .form-container {
      padding: 0 40px;

      .email-field {
        margin-right: 10px;
      }

      .subcribe-btn-container {
        .subcribe-btn {
          width: 100%;
        }
      }
    }
  }
`
export default WithDirection(BodyWraper);
