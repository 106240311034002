import { handleActions } from 'redux-actions';

const initialState = {
  mailContact: {
    result: null,
    error: null,
    requesting: false
  },
};

export const email = handleActions({
  /** MAIL CONTACT **/
  MAIL_CONTACT_REQUEST: (state) => ({
    ...state,
    mailContact: {
      ...state.mailContact,
      requesting: true
    }
  }),
  MAIL_CONTACT_SUCCESS: (state, { payload }) => ({
    ...state,
    mailContact: {
      ...state.mailContact,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  MAIL_CONTACT_FAIL: (state, { payload }) => ({
    ...state,
    mailContact: {
      ...state.mailContact,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default email;
