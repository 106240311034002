import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const NavWrapper = styled.div`
  background-color: white;
  padding: 20px 0;
  z-index: 999;
  width: 100%;
  margin-bottom: ${props => props.isInvestorBrowser ? '60px' : '0'};
  transition: margin-bottom 1s ease-in-out;
  .logo-container {
    .logo {
      width: auto;
      height: 35px;
      object-fit: contain;
      margin-left: 50px;
    }
  }

  .menu-container {
    display: flex;
    justify-content: center;

    .parent-nav {
      a {
        font-size: 15px;
        font-weight: 600;
        color: #BEBEC3;
        margin: 0 20px;
        padding: 5px 0;
      }

      #investor-label:hover {
        border-bottom: 2px solid #BEBEC3;
      }

      #investor-label:actived {
        border-bottom: 0;
      }

      a:hover {
        border-bottom: 2px solid #BEBEC3;
      }

      a:visited {
        border-bottom: 2px solid #BEBEC3;
      }

      a:actived {
        border-bottom: 2px solid #1A4CA4;
      }
    }
  }

  .get-btn-container {
    display: flex;
    justify-content: flex-end;

    .notified-btn {
      height: auto;
      background-color: #021721;
      margin-right: 50px;
      border-color: transparent;
      padding: 5px 25px;
      border-radius: 4px;

      span {
        font-weight: 600;
      }
    }
  }

  .flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
  }

  @-webkit-keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  @keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  .flipOutX {
    -webkit-animation-name: flipInX;
    animation-name: flipOutX;
  }
  @keyframes flipOutX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    } 
 } 

  .icon-container {
    .icon {
      display: none;
    }
    .icon-collapse {
      animation: flipInX .2s ease-in;
    }
    .icon-collapse-out {
      animation: flipOutX .2s ease-in;
    }
  }

  .dropdown-container {
    background-color: #fff;
    width: 100%;
    position: absolute;
    z-index: -1;
    transform: ${props => props.isToggle ? 'translateY(0)' : 'translateY(-120%)'};
    opacity: ${props => props.isToggle ? 1 : 0};
    transition: transform .2s, opacity .4s;
    .item-dropdown {
      padding: 20px;
      border-bottom: 0.5px solid #f2f2f3;

      #investor-label { 
        font-size: 15px;
        font-weight: 600;
        color: black;
      }

      .dropdown-investors-mobile {
        .children-investor {
          padding: 10px;
          border-left: 0.5px solid #f2f2f3;

          .label-children {
            font-size: 13px;
            color: black;
          }
        }
      }
    }
  }

  .dropdown-investors-browser {
    width: 100%;
    height: 60px;
    background-color: #f3f3f8;
    display: flex;
    justify-content: center;
    position: absolute;
    transform: ${props => props.isInvestorBrowser ? 'translateY(20px)' : 'translateY(-300%)'};
    opacity: ${props => props.isInvestorBrowser ? 1 : 0};
    transition: transform 1s, opacity .4s;
    .investor-item { 
      color: black;
      margin: 10px 20px;
      font-size: 13px;
      font-weight: 400;
    }

    .investor-item:hover {
      color: #2062b4;
      border-bottom: 2px solid #44444f;
    }

    .investor-item.actived {
      color: #2062b4;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 10px 0;
    height: 65px;
    .logo-container {
      .logo {
        width: auto;
        height: 35px;
        margin-left: 20px;
        max-width: 150px;
      }
    }

    .menu-container {
      display: none;
    }

    .icon-container {
      a {
        color: gray;
        font-size: x-large;
      }

      .icon {
        text-align: right;
        display: block;
        font-size: 28px;

        i:active {
          .dropdown {
            display: block;
          }
        }
      }
    }

    .get-btn-container {
      display: flex;
      justify-content: flex-end;

      .notified-btn {
        margin-right: 0;
        height: auto;
        padding: 6px 15px;

        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .dropdown-investors-browser {
      display: none
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    height: 100px;
    .logo-container {
      .logo {
        width: auto;
        height: 35px;
        margin-left: 20px;
      }
    }

    .menu-container {
      display: none;
    }

    .icon-container {
      a {
        color: gray;
        font-size: xx-large;
      }

      .icon {
        text-align: center;
        display: block;
      }
    }

    .get-btn-container {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;

      .notified-btn {
        margin-right: 0;
        height: auto;
        padding: 8px 15px;

        span {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .dropdown-container {
      .item-dropdown {
        padding: 25px;
  
        a { 
          font-size: 20px;
        }
      }
    }

    .dropdown-investors-browser {
      display: none
    }
  }
`

export default WithDirection(NavWrapper);
