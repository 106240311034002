import { createActions } from 'redux-actions';

import Api from '../api';

/** MAIL CONTACT **/
const { mailContactRequest, mailContactSuccess, mailContactFail } = createActions({
  MAIL_CONTACT_REQUEST: () => { },
  MAIL_CONTACT_SUCCESS: data => ({ data }),
  MAIL_CONTACT_FAIL: error => ({ error })
});

export const mailContact = (body) => (dispatch) => {
  dispatch(mailContactRequest());

  return Api.Email.contact(body)
    .then(({ data }) => {
      dispatch(mailContactSuccess(data));
    })
    .catch((error) => {
      dispatch(mailContactFail(error));
    });
};