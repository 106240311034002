import { handleActions } from 'redux-actions';

import { getView } from '../utils/common';

const initialState = {
  collapsed: !window.innerWidth > 1220,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  releasedDate: '',
  showSubscribe: false,
  heightScrollY: 0,
  infoStock: {
    result: null,
    error: null,
    requesting: false
  }
};

export const common = handleActions({
  CHECK_SERVICE_WORKER: (state, { payload }) => ({
    ...state,
    releasedDate: payload
  }),
  TOGGLE_COLLAPSED: (state) => ({
    ...state,
    collapsed: !state.collapsed
  }),
  TOGGLE_OPEN_DRAWER: (state) => ({
    ...state,
    openDrawer: !state.openDrawer
  }),
  HANDLE_TOGGLE_ALL: (state, { payload }) => {
    if (state.view !== payload.view || payload.height !== state.height) {
      const height = payload.height ? payload.height : state.height;
      return {
        ...state,
        collapsed: payload.collapsed,
        view: payload.view,
        height
      };
    }
    return state;
  },
  SET_SHOW_SUBSCRIBE_CONTAINER: (state, { payload }) => ({
    ...state,
    showSubscribe: payload
  }),
  GET_HEIGHT_SCROLL_Y: (state, { payload }) => ({
    ...state,
    heightScrollY: payload
  }),
  /** FETCH INFO STOCK **/
  FETCH_INFO_STOCK_REQUEST: (state) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: true
    }
  }),
  FETCH_INFO_STOCK_SUCCESS: (state, { payload }) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_INFO_STOCK_FAIL: (state, { payload }) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default common;
