import http from '../services/http';

export default class Category {
  static fetchPostByCategory(slug) {
    return http.get('/categories/findOne', {
      params: {
        filter: {
          include: [
            {
              relation: 'posts',
              scope: {
                  order: ['datePublished DESC', 'priority ASC']
              }
            }
          ],
          where: {
            slug
          }
        }
      }
    });
  }

  static fetchPost(slug) {
    return http.get('/posts/findOne', {
      params: {
        filter: {
          where: {
            slug
          }
        }
      }
    });
  }
}

