import Common from './common';
import Category from './category';
import Email from './email';
import Member from './member';

const Api = {
  Common,
  Category,
  Email,
  Member
};

export default Api;
