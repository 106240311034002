import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import WindowResizeListener from 'react-window-size-listener';
import { Debounce } from 'react-throttle';
import { get } from 'lodash';

import { toggleAll, setShowSubscribeContainer } from '../../actions/common';

import AppRouter from './routes';

import Navbar from '../Nav';
import FooterLayout from '../Footer';

import './global.css';
import AppHolder from './commonStyle';
import themes from '../../settings/themes';

import { themeConfig } from '../../settings';

const pagesHaveSubscribe = [
  'faqs',
  'overview',
  'press-release',
  'annual-report',
  'quarterly-fillings',
  'features',
  'pricing',
  'terms-of-user',
  'privacy-policy',
  'legal-notice',
  'all-press-release',
];

const pageWithoutHoc = [
  '/unsubscribe',
];

export class App extends Component {
  componentDidMount() {
    const { setShowSubscribeContainer, location } = this.props;

    const pathname = get(location, 'pathname');
    const showSubscribe = this.checkPageHaveSubscribe(pathname);
    setShowSubscribeContainer(showSubscribe);
  }

  checkPageHaveSubscribe = (pathname) => {
    // For home page
    if (pathname === '/') {
      return true;
    }

    const regex = new RegExp(pagesHaveSubscribe.join('|'), 'i');
    return regex.test(pathname);
  };

  render() {
    const { url } = this.props.match;
    const { history, location } = this.props;

    const appHeight = window.innerHeight;
    const pathName = get(location, 'pathname');

    const isWithoutHoc = pageWithoutHoc.indexOf(pathName) !== -1;

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            {
              isWithoutHoc
                ? (
                  <AppRouter style={{ height: '100%' }} url={url} />
                ) : (
                  <Layout>
                    <Debounce time="1000" handler="onResize">
                      <WindowResizeListener
                        onResize={windowSize =>
                          this.props.toggleAll(
                            windowSize.windowWidth,
                            windowSize.windowHeight
                          )
                        }
                      />
                    </Debounce>

                    <Navbar history={history} url={url} />

                    <Layout style={{ flexDirection: 'row', overflow: 'hidden' }}>
                      <Layout
                        className="isoContentMainLayout main-container"
                        style={{ height: 'auto', backgroundColor: 'white', overflowX: 'hidden' }}
                      >
                        <AppRouter url={url} />
                        <FooterLayout />
                      </Layout>
                    </Layout>
                  </Layout>
                )
            }

          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    height: state.common.height
  }),
  dispatch => bindActionCreators({
    toggleAll,
    setShowSubscribeContainer
  }, dispatch)
)(App);
