import React, { Component } from 'react';

import { Form } from 'antd';
import { get } from 'lodash';

import TextField from '@material-ui/core/TextField';

class FieldFloatingValidation extends Component {
  onChange = (e) => {
    const { form } = this.props; 

    const { handleChange } = form;

    handleChange(e);
  };

  render() {
    const {
      type,
      id,
      label,
      field,
      placeholder,
      form,
      required,
      containerClassName,
      formItemLayout,
      isDisableInput,
      inputProps,
    } = this.props;

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    return (
      <Form.Item
        {...formItemLayout}
        id={id}
        label={label}
        required={required}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
      >
        <TextField
          label="Your email"
          name={field.name}
          value={field.value || ''}
          type={type || 'text'}
          onChange={this.onChange}
          placeholder={placeholder || ''}
          disabled={isDisableInput}
          style={{ borderRadius: 5, width: '100%' }}
          {...inputProps}
        />
      </Form.Item>
    );
  }
}

export default FieldFloatingValidation;
