import { handleActions } from 'redux-actions';

const initialState = {
  categoryPosts: {
    result: [],
    error: null,
    requesting: false
  },
  post: {
    result: [],
    error: null,
    requesting: false
  }
};

export const category = handleActions({
  /** FETCH CATEGORY POSTS **/
  FETCH_CATEGORY_POSTS_REQUEST: (state) => ({
    ...state,
    categoryPosts: {
      ...state.categoryPosts,
      requesting: true
    }
  }),
  FETCH_CATEGORY_POSTS_SUCCESS: (state, { payload }) => ({
    ...state,
    categoryPosts: {
      ...state.categoryPosts,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_CATEGORY_POSTS_FAIL: (state, { payload }) => ({
    ...state,
    categoryPosts: {
      ...state.categoryPosts,
      requesting: false,
      error: payload.error
    }
  }),
  /** FETCH POST **/
  FETCH_POST_REQUEST: (state) => ({
    ...state,
    post: {
      ...state.post,
      requesting: true
    }
  }),
  FETCH_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_POST_FAIL: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default category;
