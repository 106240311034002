const siteConfig = {
  siteName: 'EMBER',
  siteIcon: `${process.env.PUBLIC_URL}/images/ember-logo.png`,
  siteIconLarge: `${process.env.PUBLIC_URL}/images/ember-white.png`,
  footerText: '© 2019 Ember LLC'
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey'
};

export { siteConfig, language, themeConfig, jwtConfig };

const setting = {
  apiUrl: 'http://yoursite.com/api/'
};
export default setting;
