import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  border: 0.5px solid #f4f4f4;
  border-radius: 20px;
  background-color: white;
  background: linear-gradient(to right, #3c6cf2, #3b6df0);
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

  .left-comming {
    width: 60%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 80px 0 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ant-form-item-explain {
      color: #ff8e8e;
    }

    .MuiInputLabel-formControl {
      top: 10%;
    }

    .MuiInputLabel-shrink {
      transform: translate(0, -4px) scale(0.75);
      transform-origin: top left;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form label {
      color: #afc5ff;
      margin-left: 10px;
    }

    .ant-form label:focused {
      margin-left: 0;
    }

    .email-field {
      display: flex;

      .email-style {
        width: 100%;
      }

      input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 10px;
        padding-right: 10px;
        color: white;
      }

      .MuiFormLabel-root.Mui-focused {
        color: #afc5ff;
      }

      .MuiInput-underline:before {
        border: 0;
      }

      .MuiInput-underline:after {
        border: 0;
      }
    }

    .privacy-intro {
      color: #afc5ff;
      margin-top: 10px;
      font-size: 13px;

      a {
        color: white;
        font-weight: 700;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .subcribe-btn {
      height: 45px;
      width: 100%;
      border: none;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: white;
      margin-top: 16px;

      span {
        color: #3b6df1;
        font-weight: 600;
      }
    }

    .comming-title {
      color: white;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .comming-intro {
      color: white;
      font-size: 14px;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }

  .ellipse-bottom {
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    position: absolute;
  }

  .right-comming {
    width: 40%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .left-phone-overlay {
    top: 0px;
    right: 14%;
    width: 30%;
    height: auto;
    position: absolute;
  }

  .right-phone-overlay {
    top: 0;
    right: 0;
    width: 21%;
    height: auto;
    position: absolute;
  }

  @media only screen and (max-width: 767px) {
    .left-comming {
      width: 100%;
      padding: 20px;
  
      .comming-title {
        font-size: 20px;
      }
  
      .comming-intro {
        font-size: 14px;
      }

      .subcribe-btn {
        width: 100%;
      }
    }

    .ellipse-bottom {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .ellipse-bottom, .right-phone-overlay, .left-phone-overlay {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .left-comming {
      width: 100%;
      padding: 0 40px 0 40px;

      .email-field {
        // margin-right: 20px;
      }
  
      .subcribe-btn {
        width: 120px;
      }
  
      .comming-title {
        font-size: 20px;
      }
  
      .comming-intro {
        font-size: 15px;
      }
    }

    .ellipse-bottom {
      width: 30%;
    }
  }

  @media (max-width: 1199px) {
    .right-phone-overlay {
      display: none;
    }

    .left-phone-overlay {
      right: 0;
      width: 36%;
    }
  }
`;

export default WithDirection(Wrapper);
