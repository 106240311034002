import { handleActions } from 'redux-actions';

const initialState = {
  members: {
    result: [],
    error: null,
    requesting: false
  },
};

export const member = handleActions({
  FETCH_MEMBERS_REQUEST: (state) => ({
    ...state,
    members: {
      ...state.members,
      requesting: true
    }
  }),
  FETCH_MEMBERS_SUCCESS: (state, { payload }) => ({
    ...state,
    members: {
      ...state.members,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_MEMBERS_FAIL: (state, { payload }) => ({
    ...state,
    members: {
      ...state.members,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default member;
