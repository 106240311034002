import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Modal } from 'antd';

import { mailContact } from '../../actions/email';
import { widthModal } from '../../utils/common';

import PropCustomForm from './form';
import BodyWraper from './styles';

class ModalEmailContact extends Component {
  onSubmit = (values, resetForm) => {
    const { mailContact, onCloseModal } = this.props;

    const reqBody = {
      email: values.email,
      type: 'SUBSCRIBER',
    };

    onCloseModal();

    mailContact(reqBody);
    if (resetForm) {
      resetForm();
    }
  };

  render() {
    const {
      title,
      messages,
      isHasForm,
      isVisible,
      onCloseModal,
    } = this.props;

    return (
      <Modal
        visible={isVisible}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 10,
        }}
        width={widthModal(window.innerWidth)}
      >
        <BodyWraper>
          <Button
            className="close-btn"
            onClick={onCloseModal}
          >
            <img src={`${process.env.PUBLIC_URL}/close.png`} alt="" />
          </Button>

          <img className="logo" src={`${process.env.PUBLIC_URL}/titlelogics/bell.png`} alt="" />
          <h1 className="title">{title}</h1>
          <p className="messages">{messages}</p>

          {
            isHasForm ? (
              <PropCustomForm
                onSubmit={this.onSubmit}
              />
            ) : (
              <Button
                className="back-btn"
                onClick={onCloseModal}
              >
                Back to home
              </Button>
            )
          }
        </BodyWraper>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    collapsed: state.common.collapsed,
  }),
  dispatch => bindActionCreators({
    mailContact,
  }, dispatch),
)(ModalEmailContact);
