import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Pages/HomePage')),
  },
  {
    path: 'contact',
    component: asyncComponent(() => import('../Pages/Contact')),
  },
  {
    path: 'FAQs',
    component: asyncComponent(() => import('../Pages/FAQs')),
  },
  {
    path: 'pricing',
    component: asyncComponent(() => import('../Pages/Pricing')),
  },
  {
    path: 'legal-notice',
    component: asyncComponent(() => import('../Pages/Legacy')),
  },
  {
    path: 'privacy-policy',
    component: asyncComponent(() => import('../Pages/Policy')),
  },
  {
    path: 'terms-of-user',
    component: asyncComponent(() => import('../Pages/Terms')),
  },
  {
    path: 'features',
    component: asyncComponent(() => import('../Pages/Features')),
  },
  {
    path: 'unsubscribe',
    component: asyncComponent(() => import('../Pages/Unsubscribe')),
  },
  {
    component: asyncComponent(() => import('../Pages/HomePage')),
  }
];

class AppRouter
  extends Component {
  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map((singleRoute, index) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={index}
                path={`/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
          <Redirect to='/' />
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
