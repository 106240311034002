import http from '../services/http';

export default class Member {
  static fetchMembers() {
    return http.get('/members', {
      params: {
        filter: {
          where: { isActive: true },
          order: ['priority ASC', 'createdAt DESC']
        }
      }
    });
  }
}
